
import { defineComponent, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { BehavioralWord } from "../../../core/types/BehavioralTypes";

export default defineComponent({
  name: "edit-behavioral-word-modal",
  emit: ["modalClose"],
  props: { behavioralwordid: Number },
  components: { Field, Form, ErrorMessage },
  setup(props) {
    const store = useStore();
    const wordInfo = computed(() => {
      return store.getters.getBehavioralWordById(
        Number(props.behavioralwordid)
      );
    });
    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const wordValidation = Yup.object().shape({
      word: Yup.string().required().label("Behavioral Word"),
      spanishWord: Yup.string().required().label("Espanol"),
    });
    const behavioralWord = ref<BehavioralWord>({
      behavioralWordId: wordInfo.value.behavioralWordId,
      word: wordInfo.value.word,
      spanishWord: wordInfo.value.spanishWord,
      active: wordInfo.value.active,
      order: wordInfo.value.order,
      behavioralWordTraits: wordInfo.value.behavioralWordTraits,
    });
    const principalTraitList = computed(() => {
      return store.getters.getPrincipalTraitList;
    });

    watch(wordInfo, () => {
      behavioralWord.value.word = wordInfo.value.word;
      behavioralWord.value.spanishWord = wordInfo.value.spanishWord;
      behavioralWord.value.active = wordInfo.value.active;
      behavioralWord.value.order = wordInfo.value.order;
      behavioralWord.value.behavioralWordTraits =
        wordInfo.value.behavioralWordTraits;
    });

    const onSubmitWord = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      behavioralWord.value.behavioralWordId = Number(props.behavioralwordid);
      // Send login request
      store
        .dispatch(Actions.PUT_BEHAVIORAL_WORD, behavioralWord.value)
        .then(() => {
          Swal.fire({
            text: "Success. The word has been updated.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
    }

    return {
      behavioralWord,
      principalTraitList,
      wordInfo,
      closeModal,
      modalRef,
      wordValidation,
      onSubmitWord,
      submitButton,
    };
  },
});
