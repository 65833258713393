
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { BehavioralWord } from "../../../core/types/BehavioralTypes";

export default defineComponent({
  name: "new-behavioral-word-modal",
  props: {},
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);
    const modalRef = ref<null | HTMLFormElement>(null);

    const behavioralWord = ref<BehavioralWord>({
      behavioralWordId: 0,
      word: "",
      spanishWord: "",
      active: true,
      order: 0,
      behavioralWordTraits: [],
    });

    const principalTraitList = computed(() => {
      return store.getters.getPrincipalTraitList;
    });

    const wordValidation = Yup.object().shape({
      word: Yup.string().required().label("Word"),
      spanishWord: Yup.string().required().label("Espanol"),
    });

    const onSubmitClient = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.CREATE_BEHAVIORAL_WORD, behavioralWord.value)
        .then(() => {
          Swal.fire({
            text: "Success. The new word has been added.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
            modalRef.value?.click();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function closeModal() {
      modalRef.value?.click();
      resetForm();
    }

    function resetForm() {
      behavioralWord.value = {
        behavioralWordId: 0,
        word: "",
        spanishWord: "",
        active: true,
        order: 0,
        behavioralWordTraits: [],
      };
    }

    return {
      behavioralWord,
      principalTraitList,
      closeModal,
      resetForm,
      modalRef,
      wordValidation,
      onSubmitClient,
      submitButton,
    };
  },
});
