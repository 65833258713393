<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin:Licensed Distributor Account Info-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-700 text-hover-primary fs-2 fw-bolder me-1"
                  >Behavorial Word List
                </a>
              </div>
              <!--end::Name-->
            </div>
            <!--end:Licensed Distributor Account Info-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Total Words-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Total Words-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">Total Words</div>
                  </div>
                  <!--end::Total Words-->

                  <!--begin::Word Length-->
                  <div class="fw-bold fs-4 text-gray-700">
                    {{ behavioralWords.length }}
                  </div>
                  <!--end::Word Length-->
                </div>
                <!--end::Total Words-->
                <!--begin::Active-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">Active</div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-4 text-gray-600">
                    {{ activeWords }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Active-->

                <!--begin::By Trait-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">By Trait</div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Traits-->
                  <div style="display: flex; gap: 10px">
                    <div class="incline-trait-circle incline-primary-red-bkgrd">
                      {{ autonomyCount }}
                    </div>
                    <div
                      class="incline-trait-circle incline-primary-yellow-bkgrd"
                    >
                      {{ socialCount }}
                    </div>
                    <div
                      class="incline-trait-circle incline-primary-green-bkgrd"
                    >
                      {{ patienceCount }}
                    </div>
                    <div
                      class="incline-trait-circle incline-primary-blue-bkgrd"
                    >
                      {{ certaintyCount }}
                    </div>
                    <div class="incline-trait-circle incline-black-bkgrd">
                      {{ judgementCount }}
                    </div>
                  </div>
                  <!--end::Traits-->
                </div>
                <!--end::By Trait-->
                <!--begin::Distractors-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Total Words-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">Distractors</div>
                  </div>
                  <!--end::Total Words-->

                  <!--begin::Word Length-->
                  <div class="fw-bold fs-4 text-gray-700">
                    {{ distractorCount }}
                  </div>
                  <!--end::Word Length-->
                </div>
                <!--end::Distractors-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Word List</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <a
              href="#"
              class="btn btn-sm btn-flex btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal_new_behavioral-word"
            >
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/general/gen035.svg" />
              </span>
              Add new word</a
            >
          </div>
          <!--end::Card toolbar-->
          <!--begin::Search-->
          <input
            type="text"
            class="form-control form-control-lg form-control-solid px-5 mb-2"
            v-model="searchWords"
            name="search"
            placeholder="Search by word or trait..."
            data-kt-search-element="input"
          />
          <p class="px-5 mb-5">{{ filterBehavioralWords.length }} returned</p>
        </div>
        <!--end::Search-->
        <!--begin::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0" v-if="isDataReady">
          <!--begin::Word Container-->
          <table class="table table-striped gy-3 gs-3">
            <thead>
              <tr class="fw-bolder fs-5 text-gray-800">
                <th>Word</th>
                <th>Espanol</th>
                <th>Trait(s)</th>
                <th>Order</th>
                <th>Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in filterBehavioralWords"
                :key="item.behavioralWordId"
                :behavioralWordId="'word_' + index"
              >
                <td>
                  <div class="d-flex flex-column">
                    <p class="d-flex align-items-center py-2">
                      <strong>{{ item.word }}</strong>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <p class="d-flex align-items-center py-2">
                      <strong>{{ item.spanishWord }}</strong>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <p class="d-flex align-items-center py-2">
                      {{ item.traits }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <p class="d-flex align-items-center py-2">
                      {{ item.order }}
                    </p>
                  </div>
                </td>
                <td>
                  <span v-if="item.active" class="badge badge-success"
                    >Active</span
                  >
                  <span v-else class="badge badge-danger">In-Active</span>
                </td>
                <td>
                  <!--begin::Edit-->
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#modal_edit_behavioral_word"
                    @click="editWordClick(Number(item.behavioralWordId))"
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      title="Edit"
                      data-bs-original-title="Edit"
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                      </span>
                    </span>
                  </a>
                  <!--end::Edit-->
                  <!--begin::Activate/Deactivate-->
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                    data-bs-toggle="tooltip"
                    title="Activate/Deactivate"
                    data-kt-customer-payment-method="Activate/Deactivate"
                    data-bs-original-title="Activate/Deactivate"
                    @click="activateDeactivateWord(item.behavioralWordId)"
                  >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="/media/icons/duotune/general/gen019.svg"
                      />
                    </span>
                  </a>
                  <!--end::Activate/Deactivate-->
                </td>
              </tr>
            </tbody>
          </table>
          <!--begin::Word Container-->
        </div>
        <div v-else>
          <TableSkeleton></TableSkeleton>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <NewBehavioralWordModal></NewBehavioralWordModal>
  <EditBehavioralWordModal
    :behavioralwordid="Number(wordid)"
  ></EditBehavioralWordModal>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import NewBehavioralWordModal from "@/components/modals/forms/NewBehavioralWordModal.vue";
import EditBehavioralWordModal from "@/components/modals/forms/EditBehavioralWordModal.vue";

export default defineComponent({
  name: "incline-behavioral-words",
  props: { behavioralwordid: Number },
  components: {
    NewBehavioralWordModal,
    EditBehavioralWordModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    const behavioralWords = computed(() => {
      return store.getters.getBehavioralWordsList;
    });
    const autonomyCount = computed(() => {
      return store.getters.getAutonomyTraitCount;
    });
    const socialCount = computed(() => {
      return store.getters.getSocialTraitCount;
    });
    const patienceCount = computed(() => {
      return store.getters.getPatienceTraitCount;
    });
    const certaintyCount = computed(() => {
      return store.getters.getCertaintyTraitCount;
    });
    const judgementCount = computed(() => {
      return store.getters.getJudgementTraitCount;
    });
    const distractorCount = computed(() => {
      return store.getters.getDistractorCount;
    });
    const activeWords = computed(() => {
      return store.getters.getActiveBehavioralWords;
    });
    const searchWords = ref("");

    onMounted(() => {
      setCurrentPageTitle("Behavorial Words");
      setCurrentPageBreadcrumbs("Behavorial Words", ["Admin"]);
    });
    const wordid = ref<number>();
    const editWordClick = (id: number) => {
      wordid.value = id;
    };

    store
      .dispatch(Actions.GET_BEHAVIORAL_WORDS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving words.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    store
      .dispatch(Actions.GET_PRINCIPAL_TRAITS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving principal traits",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const activateDeactivateWord = (id) => {
      Swal.fire({
        title: "Are you sure you want to change the active status this word?",
        showCancelButton: true,
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DEACTIVATE_REACTIVATE_BEHAVIORAL_WORD, id)
            .then(() => {
              Swal.fire("The word has been updated!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem updating your word.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const filterBehavioralWords = computed(() => {
      return behavioralWords.value.filter((behavioralWord) => {
        return (
          behavioralWord.word
            .toLowerCase()
            .indexOf(searchWords.value.toLowerCase()) != -1 ||
          behavioralWord.traits
            .toLowerCase()
            .indexOf(searchWords.value.toLowerCase()) != -1
        );
      });
    });

    watch(behavioralWords, () => {
      isDataReady.value = true;
    });

    return {
      isDataReady,
      wordid,
      editWordClick,
      searchWords,
      filterBehavioralWords,
      behavioralWords,
      autonomyCount,
      socialCount,
      patienceCount,
      certaintyCount,
      judgementCount,
      distractorCount,
      activeWords,
      activateDeactivateWord,
    };
  },
});
</script>
