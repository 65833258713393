
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import NewBehavioralWordModal from "@/components/modals/forms/NewBehavioralWordModal.vue";
import EditBehavioralWordModal from "@/components/modals/forms/EditBehavioralWordModal.vue";

export default defineComponent({
  name: "incline-behavioral-words",
  props: { behavioralwordid: Number },
  components: {
    NewBehavioralWordModal,
    EditBehavioralWordModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    const behavioralWords = computed(() => {
      return store.getters.getBehavioralWordsList;
    });
    const autonomyCount = computed(() => {
      return store.getters.getAutonomyTraitCount;
    });
    const socialCount = computed(() => {
      return store.getters.getSocialTraitCount;
    });
    const patienceCount = computed(() => {
      return store.getters.getPatienceTraitCount;
    });
    const certaintyCount = computed(() => {
      return store.getters.getCertaintyTraitCount;
    });
    const judgementCount = computed(() => {
      return store.getters.getJudgementTraitCount;
    });
    const distractorCount = computed(() => {
      return store.getters.getDistractorCount;
    });
    const activeWords = computed(() => {
      return store.getters.getActiveBehavioralWords;
    });
    const searchWords = ref("");

    onMounted(() => {
      setCurrentPageTitle("Behavorial Words");
      setCurrentPageBreadcrumbs("Behavorial Words", ["Admin"]);
    });
    const wordid = ref<number>();
    const editWordClick = (id: number) => {
      wordid.value = id;
    };

    store
      .dispatch(Actions.GET_BEHAVIORAL_WORDS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving words.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    store
      .dispatch(Actions.GET_PRINCIPAL_TRAITS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving principal traits",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    const activateDeactivateWord = (id) => {
      Swal.fire({
        title: "Are you sure you want to change the active status this word?",
        showCancelButton: true,
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DEACTIVATE_REACTIVATE_BEHAVIORAL_WORD, id)
            .then(() => {
              Swal.fire("The word has been updated!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem updating your word.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const filterBehavioralWords = computed(() => {
      return behavioralWords.value.filter((behavioralWord) => {
        return (
          behavioralWord.word
            .toLowerCase()
            .indexOf(searchWords.value.toLowerCase()) != -1 ||
          behavioralWord.traits
            .toLowerCase()
            .indexOf(searchWords.value.toLowerCase()) != -1
        );
      });
    });

    watch(behavioralWords, () => {
      isDataReady.value = true;
    });

    return {
      isDataReady,
      wordid,
      editWordClick,
      searchWords,
      filterBehavioralWords,
      behavioralWords,
      autonomyCount,
      socialCount,
      patienceCount,
      certaintyCount,
      judgementCount,
      distractorCount,
      activeWords,
      activateDeactivateWord,
    };
  },
});
